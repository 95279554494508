* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
body, html {
  margin: 0;
  display: flex;
  height: 100%;
  width: 100%;
}

body {
  width: 100%;
  height: 100vh;
  font-family: "mono45-headline", sans-serif;
}

::-webkit-scrollbar{
  display: none;
}
::-webkit-scrollbar-button{
  display: none;
}

::-webkit-scrollbar-track{
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#containeria {
  height: 100vh;
  width: 100vw;
  display: flex;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  60% { opacity: 0; }
  100% {opacity: 1;}
}

.panel {
  height: 100vh;
  color: white;
  overflow: hidden;
  flex: 0.3;
  position: relative;
  transition: flex 0.5s linear;
  width: 100%;
}

.panel.active {
  flex-grow: 10;
}

.fill {
  height: 100vh;
  min-height: 100vh;
  padding: 0;
}

.sides {
  height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;
}

.side1 {
  background: #D9D9D9;
  width: -webkit-fill-available;
}

.side2 {
  background: #737373;
  width: -webkit-fill-available;
}

.side4 {
  background-color: #BEBEBE;
  width: -webkit-fill-available;
}


.side3 {
  background: #404040;
  width: -webkit-fill-available;
}

.sidetext {
  writing-mode: vertical-rl; 
  font-family: 'mono45-Headline';
  font-style: normal;
  font-weight: 400;
  font-size: 1.25em;
  line-height: 24px;
  white-space: nowrap;
}

.header {
  margin-top: 20%;
  font-family: 'Mono45-Headline';
  font-style: normal;
  font-weight: 400;
  font-size: 3em;
  line-height: 48px;
  text-align: center;
  color: #404040;
}

.header2 {
  margin-top: 5%;
  font-family: 'Mono45-Headline';
  font-style: normal;
  font-weight: 400;
  font-size: 3em;
  line-height: 48px;
  text-align: center;
  color: #404040;
}

.stats {
  font-family: 'Mono45-Headline';
  font-style: normal;
  font-weight: 400;
  font-size: 8em;
  line-height: 128px;
  text-align: center;
  
  color: #404040;
}

.samaHeader {
  margin-top: 5%;
  font-family: 'Mono45-Headline';
  font-style: normal;
  font-weight: 400;
  font-size: 2.4em;
  line-height: 48px;
  text-align: left;
  color: #404040;
  padding-left: 2.5%;
}

.samaParagraph {
  margin-top: 10%;
  font-family: 'Mono45-Headline';
  font-style: normal;
  font-weight: 400;
  font-size: 1.25em;
  line-height: 28px;
  text-align: left;
  color: #404040;
  padding-right: 5%;
  padding-left: 2.5%;
}

.leaderboard {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: 100%;
  align-items: center;
}

.leaderboardnav {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

.lbtn {
  padding: 10px 15px;
  border: none;
  background-color: #BEBEBE;
  color: black;
  font-size: 20px;
  font-weight: 400;
  border-radius: 1rem;
}

.lbtn:hover {
  background-color: white;
}

.lbtn.active {
  background-color: white;
}

.leaders {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: white;
  border-radius: 1rem;
  width: 35%;
  padding: 15px;
  overflow-y: scroll;
  height: 65%;
}

.leader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: black;
  font-size: 1rem;
  font-weight: 200;
}
.lead {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  color: black;
  font-size: 1rem;
  font-weight: 200;
}